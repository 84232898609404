import { delay, takeWhile } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';

@Component({
  selector: 'ngx-traffic-chart',
  template: `
    <div>
      <chart type="line" 
        [data]="dataSalesLast6Months" 
        [options]="optionsSalesLast6Months">
      </chart>
    </div>
  `,
})
export class TrafficChartComponent {

  dataSalesLast6Months: any;
  optionsSalesLast6Months: any;
  color:any = [ '#00FF00','#FF0000'];
  
  @Input('points')
  set chartValue(obj: any) {
    this. SalesLast6Months(obj);
  }

  option: any = {};
  echartsIntance: any;
  _config: any;
  lbIn = 'Inbound traffic';
  lbOut = 'Outbound traffic';
  constructor(private theme: NbThemeService,
              private layoutService: LayoutService) {

  }

  SalesLast6Months(datas){

    var that = this;
    
    this.dataSalesLast6Months = {
       labels: [0,0,0,0,0,0,0,0],
      datasets: [
        {
          labels: [this.lbIn,this.lbIn,this.lbIn,this.lbIn,this.lbIn,this.lbIn,this.lbIn,this.lbIn],
          data: datas.inbound,
          backgroundColor:this.color[0],
          borderColor:this.color[0],
          borderWidth: 1,
          fill: false,
        },
        {
          labels: [this.lbOut,this.lbOut,this.lbOut,this.lbOut,this.lbOut,this.lbOut,this.lbOut,this.lbOut],
          data: datas.outbound,
          backgroundColor:this.color[1],
          borderColor:this.color[1],
          borderWidth: 1,
          fill: false,
        }
      ]
    };

    this.optionsSalesLast6Months =   {
      animation: {
        duration: 0
      },elements: {
          line: {
              tension: 0
          }
      },
      legend: { 
        display: false,
      },
      scales: {
          yAxes: [{
            ticks: {
                display: false,
                beginAtZero: true
            }
          }],
          xAxes: [{
              ticks: {
                display: false
            }
          }]
      },
      tooltips: {
          mode: 'nearest',
          position: 'average',
          callbacks: {
              title: function(tooltipItem, chart) {

                return chart.datasets[tooltipItem[0].datasetIndex].labels[tooltipItem[0].index];
              },
              label: function (tooltipItem, chart) {

                  let value = chart.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                  
                  return that.niceBytes(value);
                  }
              }
          }
      }

  }

  niceBytes(x): string {
    if(x == null) x = 0;
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while (n >= 1024) {
        n = n / 1024;
        l++;
    }

      return (n.toFixed(2) + ' ' + units[l]);
}
}
