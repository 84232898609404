import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { PagesGuard } from './pages/pages.guard';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

export const routes: Routes = [
  {
    path: 'pages',
    canActivate:[PagesGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./luancher/login/login.module')
      .then(m => m.LoginModule),
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./luancher/maintenance/maintenance.module')
      .then(m => m.MaintenanceModule),
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./luancher/reset/reset.module')
      .then(m => m.ResetModule),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
