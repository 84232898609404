import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ViewCell } from 'ng2-smart-table';

@Component({
    selector: 'button-view',
    template: ` 
    <div class="button activity-custom-button">      
      <a class="custom-button-view" *ngIf="!urls" href='javascript:void(0)' authorized="{{authorized}}">
        <button type="button" class="btn btn-primary btn-icon" title="Audit Report" authorized="{{authorized}}">
          <i class="fas fa-chart-bar"></i>
        </button>
      </a>
      <a class="custom-button-view" *ngIf="urls" authorized="{{authorized}}">
        <button type="button" class="btn btn-primary btn-icon" (click)="onClick()" title="Audit Report" authorized="{{authorized}}">
          <i class="fas fa-chart-bar"></i>
        </button>
      </a>
    </div>    
  `,
    styleUrls: ['./activity-logs-button-view.scss'],
})
export class ActivityLogsButtonViewComponent implements ViewCell, OnInit {

    caption: string;
    linkUrl: string;
    id: string;
    authorized: string;
    urls: Array<string> = [];
    fragment: string = "";

    constructor(private router: Router) {

    }
    // value should be an object.
    @Input() value: any;
    @Input() rowData: any;

    @Output() click = new EventEmitter<any>();

    ngOnInit() {

        this.caption = this.value.caption;
        
        this.authorized = this.value.authorized;
    }

    onClick() {

      this.click.emit(this.rowData);
    }
} 
