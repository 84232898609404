import { delay } from 'rxjs/operators';
import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';

declare const echarts: any;

@Component({
  selector: 'ngx-solar',
  styleUrls: ['./solar.component.scss'],
  template: `
    <nb-card size="tiny" class="solar-card"  [ngClass]="{'solar-card primary':status!=''}" [status]="status">
      <nb-card-header>{{title}}</nb-card-header>
      <nb-card-body>
        <div class="echart">
          <chart type="doughnut" 
              [data]="data" 
              [options]="options">
          </chart>
          <div class="insideDoughnut">
            <h5>{{value}} %</h5>
          </div>
        </div>
        <div class="info">
          <div class="h4 value"> {{used}}</div>
          <div class="details subtitle-2">out of {{size}}</div>
        </div>
      </nb-card-body>
    </nb-card>
  `,
})
export class SolarComponent implements AfterViewInit {

  value = 0;
  title = "";
  used = "";
  size = "";
  status = "";

  @Input('chartValue')
  set chartValue(obj: any) {

    this.value = obj.value;
    
    this.TotalCustomerByStatus(this.value,100 - this.value);

    this.title= obj.title;
    this.used= obj.used;
    this.size= obj.size;
    if(obj.primary) this.status="primary";
  }

  option: any = {};
  themeSubscription: any;
  _config: any;

  
  data:any;
  options:any;

  color:any = ['#3367FF', '#1A2138'];


  constructor(private theme: NbThemeService) {
  }

  ngAfterViewInit() {

    this.TotalCustomerByStatus(this.value,100 - this.value);
  }

  TotalCustomerByStatus(active,inactive){

    this.data = {
      labels: ['Used Space', 'Available Space'],
      datasets: [
        {
          data:[active,inactive],
          backgroundColor: this.color,
          borderWidth: 0
        }
      ]
    };

    this.options =  {
      animation: {
          duration: 0
      },
      tooltips: {
        enabled: false
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      title: {
          display: false,
      },
      cutoutPercentage: 85,
    }

  }

}
