import { Injectable } from '@angular/core';
import { Http } from '../pages/shared/http-client/http-client.component';
import { Router } from "@angular/router";
import { EnvironmentSpecificService } from "../environment-specific.service";

import 'rxjs/add/operator/map';
@Injectable()
export class ThemeService {

    apiUrl:any;

    constructor(private _http: Http,private _envService: EnvironmentSpecificService) {

        this.apiUrl = this._envService.envSpecific.apiUrl;
    }

    GetUserInfo(){
        return this._http.getCurrentUser();
    }

    LogOut(){

        return this._http.get(this.apiUrl  + '/authentication/logout').map((response: Response) => {

            this._http.ClearCurrentUser();
            return response;
        });
    
            
    }

}
