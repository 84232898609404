import { Component } from '@angular/core';
import './ckeditor.loader';
import 'ckeditor';

@Component({
    selector: 'ckeditorcomponent',
    templateUrl: './ckeditor.component.html',
    styleUrls: ['./ckeditor.component.scss']
})
export class CKEDITORCOMPONENT {


    config:any = { 
        extraPlugins: 'divarea', 
        height: '320', 
        resize_enabled : false 
    }
}