import { Component, OnDestroy, Input } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

import { TrafficChartData } from '../../../@core/data/traffic-chart';

@Component({
  selector: 'ngx-traffic',
  styleUrls: ['./traffic.component.scss'],
  template: `
    <nb-card size="tiny"  [ngClass]="{'solar-card primary':status!=''}" [status]="status">
      <nb-card-header>
        <span>{{title}}</span>
      </nb-card-header>
      <ngx-traffic-chart [points]="points"></ngx-traffic-chart>
    </nb-card>
  `,
})
export class TrafficComponent implements OnDestroy {

  private alive = true;
  status:any = "";
  points:any;
  title:any = "";
  @Input('points')
  set chartValue(obj: any) {

    this.points = obj;
    this.title = obj.title;
    if(obj.primary) this.status="primary";
  }

  constructor(private themeService: NbThemeService,
              private trafficChartService: TrafficChartData) {
              
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
