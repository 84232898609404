import { Directive, ElementRef, Renderer2, Input, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Directive decorator
@Directive({ selector: '[authorized]' })
// Directive class
export class AuthorizedDirective {

    private isAllFiles: boolean = false;
    private role: any;
    _currentUser:any;
    _role:any;

    constructor(public el: ElementRef, public renderer: Renderer2, public route: ActivatedRoute) { 
        this._currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this._role = this._currentUser.role;
    }

    @Input() authorized: string;

    @HostListener('click', ['$event'])
    handleMouseEnter(event: Event) {
    
        if(!this.authorized.includes(this._role)){

            event.stopPropagation();
            event.preventDefault();
            return false;
        }else{
            return true;
        }
    }

    ngOnInit() {

        if(!this.authorized.includes(this._role)){
            this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
            this.el.nativeElement.classList.add('disabled');
    
    
            if (this.el.nativeElement.localName == 'a') {
    
                this.renderer.setAttribute(this.el.nativeElement, "routerlink", null);
                this.renderer.setAttribute(this.el.nativeElement, "click", null);
                this.renderer.setAttribute(this.el.nativeElement, "href", null);
            }
    
            if (this.el.nativeElement.localName == 'button') {
    
                this.renderer.setAttribute(this.el.nativeElement, "click", '');
            }
    
    
            if (this.el.nativeElement.localName == "ng2-smart-table") {
    
                this.renderer.setAttribute(this.el.nativeElement, "edit", '');
            }

            if (this.el.nativeElement.localName == 'i') {
    
                this.el.nativeElement.classList.add('hidden');
            }
        }
        
    }
}