import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LocalDataSource, ViewCell } from 'ng2-smart-table';

@Component({
    selector: 'action-custom-view',
    template: `
    <div class="actions-button">
    <a class="ng2-smart-action ng2-smart-action-edit-custom" (click)="onClick()" title="{{customTitle}}" *ngIf="!isHideCustomButton">
      <i class="{{ customIcon }}"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onEdit()" title="{{editTitle}}" *ngIf="!isHideEditButton" authorized="{{_roles}}">
      <i class="nb-edit"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-delete-delete" (click)="onDelete()" [ngClass]="{'inActive': rowData.status == 'EXPIRED'  || rowData.isDisabled}" title="{{deleteTitle}}" *ngIf="!isHideDeleteButton" authorized="{{_roles}}">
      <i class="{{ deleteIcon }}"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-shared-shared" [ngStyle]="{'color': rowData.shared ? '#08b4a0' : 'white'}" (click)="onShared()" title="{{sharedTitle}}" *ngIf="!isHideSharedButton">
        <i class="fa fa-share-alt"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-download-download" (click)="onDownload()" title="{{downloadTitle}}" *ngIf="!isHideDownloadButton">
        <i class="fa fa-cloud-download"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onDeviceStorage()" [ngClass]="{'inActive': rowData.status.indexOf('red') > 0 }" title="{{deviceStorageTitle}}" *ngIf="!isHideDeviceStorageButton">
        <i class="fas fa-sd-card"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onManageDevice()" [ngClass]="{'inActive': !rowData.status}" title="{{customTitle}}" *ngIf="!isHideManageDeviceButton">
        <i class="fas fa-tools"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onStorageDownload()" title="{{deviceStorageTitle}}" *ngIf="!isHideStorageDownloadButton">
        <i class="fas fa-download"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onCreditCard()" [ngClass]="{'inActive': rowData.status.indexOf('red') > 0 }" title="{{creditcardTitle}}" *ngIf="!isHideCreditCardButton">
        <i class="far fa-credit-card"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onTerminate()" [ngClass]="{'inActive': !rowData.status }"  [class.disabled]="!rowData.status" title="{{terminateTitle}}" *ngIf="!isHideTerminateButton">
        <i class="fas fa-times-circle"></i>
    </a>
    <a class="ng2-smart-action ng2-smart-action-edit-edit" (click)="onView()" title="{{viewTitle}}" *ngIf="!isHideViewButton">
        <i class="fas fa-eye"></i>
    </a>
  </div>
  `,
    styleUrls: ['./custom-button-view.scss'],
})
export class CustomActionViewComponent implements ViewCell, OnInit {
    _roles = "admin,account";
    editAuthorized: string = "";
    deleteAuthorized: string = "";
    customAuthorized: string = "";
    sharedAuthorized: string = "";
    downloadAuthorized: boolean = false;

    editTitle: string = "Edit";
    deleteTitle: string = "Activate/Deactivate";
    customTitle: string = "Custom";
    sharedTitle: string ="Share";
    downloadTitle: string ="Download";
    creditcardTitle: string = "Payment";
    deviceStorageTitle: string = "Access Device Storage";
    terminateTitle: string = "Terminate";
    viewTitle: string = "View";

    isHideEditButton: boolean = false;
    isHideDeleteButton: boolean = false;
    isHideCustomButton: boolean = true;
    isHideSharedButton: boolean = true;
    isHideDownloadButton: boolean = true;
    isHideDeviceStorageButton: boolean = true;
    isHideManageDeviceButton: boolean = true;
    isHideCreditCardButton: boolean = true;
    isHideStorageDownloadButton: boolean = true;
    isHideTerminateButton: boolean = true;
    isHideViewButton: boolean = true;
    customIcon: string = "ion-edit";
    deleteIcon: string = "fas fa-toggle-on";
    _currentUser:any;
    _role:any;

    @Input() value: any;
    @Input() rowData: any;

    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() view: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();
    @Output() custom: EventEmitter<any> = new EventEmitter();
    @Output() shared: EventEmitter<any> = new EventEmitter();
    @Output() download: EventEmitter<any> = new EventEmitter();
    @Output() terminate: EventEmitter<any> = new EventEmitter();

    ngOnInit() {

        this._currentUser = JSON.parse(localStorage.getItem("currentUser"));
        this._role = this._currentUser.role;

        this.editAuthorized = this.value.editAuthorized;
        this.deleteAuthorized = this.value.deleteAuthorized;
        this.sharedAuthorized = this.value.sharedAuthorized;
        this.downloadAuthorized = this.value.downloadAuthorized;

        if (this.value.editTitle) {

            this.editTitle = this.value.editTitle;
        }

        if (this.value.deleteTitle) {

            this.deleteTitle = this.value.deleteTitle;
        }

        if (this.value.hideEditButton) {

            this.isHideEditButton = this.value.hideEditButton;
        }

        if (this.value.hideDeviceStorageButton != undefined) {

            this.isHideDeviceStorageButton = this.value.hideDeviceStorageButton;
        }
 
        if (this.value.hideManageDeviceButton != undefined) {
   
            this.isHideManageDeviceButton = this.value.hideManageDeviceButton;
        }
        if(this.value.hideCreditCardButton != undefined){ 

            this.isHideCreditCardButton = this.value.hideCreditCardButton
        }

        if(this.value.hideTerminateButton != undefined){ 

            this.isHideTerminateButton = this.value.hideTerminateButton
        }
        
        if(this.value.hideViewButton != undefined){ 

            this.isHideViewButton = this.value.hideViewButton
        }

        if (this.value.hideStorageDownloadButton != undefined) {

            this.isHideStorageDownloadButton = this.value.hideStorageDownloadButton;
        }

        if (this.value.hideDeleteButton) {

            this.isHideDeleteButton = this.value.hideDeleteButton;
        }

        // this.isHideDownloadButton = this.value.hideDownloadButton;

        if (this.value.hideDownloadButton) {
            this.isHideDownloadButton =  this.value.hideDownloadButton;
        }

        if (this.value.customTitle) {

            this.isHideCustomButton = false;

            this.customTitle = this.value.customTitle;
            this.customAuthorized = this.value.customAuthorized;
            this.customIcon = this.value.customIcon;

            if (this.value.custom) {

                this.customIcon = this.value.custom;
            }
        }

        if (this.value.deleteIcon) {

            this.deleteIcon = this.value.deleteIcon;
        }

        if (this.value.sharedTitle) {

            this.isHideSharedButton = false;
        }

        if (this.value.downloadTitle) {

            this.isHideDownloadButton = false;
        }
    }

    onEdit() {
        if(this.checkPermission()){
            this.edit.emit(this.rowData);
        }

    }

    onView() {

        this.view.emit(this.rowData);
    }

    onDeviceStorage() {

        this.edit.emit(this.rowData);
    }

    onCreditCard() {

        this.edit.emit(this.rowData);
    }

    onTerminate() {

        this.terminate.emit(this.rowData);
    }

    onStorageDownload(){
        
        this.edit.emit(this.rowData);
    }
    
    onDelete() {

        if(this.checkPermission()){
            this.delete.emit(this.rowData);
        }
    }

    onClick() {

        this.custom.emit(this.rowData);
    }

    onShared() {

        this.shared.emit(this.rowData);
    }

    onDownload() {

        if (this.downloadAuthorized) {

            this.download.emit(this.rowData);
        }
    }

    onManageDevice() {

        this.edit.emit(this.rowData);
    }

    checkPermission() {

        if(this._roles.includes(this._role)){

            return true;
        }else{
            return false;
        }
    }
    
} 
