<div class="modal-content dialog-modal-card">
    <div class="modal-header">
        <h4 class="modal-title">Add Deposit Interest เพิ่มดอกเบี้ยเงินฝาก</h4>
    </div>
    <form #form="ngForm" class="form-horizontal" novalidate (ngSubmit)="onSubmit(form.value, form.valid)" autocomplete="off">
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <label for="interest">Deposit interest:</label>
                    <div class="form-group" [ngClass]="{'has-error': interest.touched && interest.errors}">
                        <input type="text" autocomplete="off" class="form-control" id="interest" name="interest" nbInput fullWidth maxlength="7"
                          placeholder="Deposit interest" ngModel required #interest="ngModel"  pattern="(^\d+(\.)\d{2}$)|\d+" 
                          [(ngModel)]="data.interest" reverse="true" autocomplete="off"/>
                        <div class="input alert alert-danger" *ngIf="(submitted && !interest.valid) || (interest.touched && !interest.valid)">
                          <div *ngIf="interest.errors.required">Deposit interest is required</div>
                          <div *ngIf="interest && interest.errors.pattern">Deposit interest  must be currency format</div>
                        </div>
                      </div>
                </div>
                <div class="col-12">
                    <label for="interest">Comment:</label>
                    <textarea nbInput rows="4" ngModel id="note" name="note" #note="ngModel"
                    [(ngModel)]="data.note" maxlength="100" fullWidth
                    placeholder="Comments"></textarea>
                </div>
            </div>
        </div>
        <div class="modal-footer row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
         
                    </div>
                    <div class="col-md-6 right">
                        <button type="submit" nbButton status="primary">Submit</button>
                        <button type="button" nbButton status="danger" (click)="onExit()">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>