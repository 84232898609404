import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created by Gong 2021
    </span>
    <div class="socials" style="display:none">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
    <div class="socials" >
    <a href="https://www.facebook.com/groups/165548410140270" title="PVCMAN facebook group" target="_blank" class="ion ion-social-facebook"></a>
  </div>
  `,
})
export class FooterComponent {
}
