<div class="toggle">
    <label class="toggle-input">
        <input type="checkbox" 
        checked="{{ toggleStatus ? 'checked' : '' }}" 
        (click)="onToggleButtonClick($event)">
        <span></span>
    </label>
    <span class="toggle-caption" >
        {{ toggleButtonCaption }}
    </span>
</div>