import { Component,ViewChild, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'dialog-change-password',
  templateUrl: './dialog-change-password.html',
  styleUrls: ['./dialog-change-password.scss'],
})
export class DialogChangePasswordComponent {

    @ViewChild("form") form: any;

    data: any = {
        currentpassword:'',
        newpassword:'',
        confirmpassword:''
    };
    submitted: boolean = false;

    constructor(protected ref: NbDialogRef<DialogChangePasswordComponent>) {

    }

    dismiss() {
        this.ref.close();
    }

  
    private closeModal(result: any) {
        this.ref.close(result);
    }

    onExit() {

        this.closeModal({ status: false });
    }

    onSubmit(formData: any, isValid: boolean): void {

        if (!isValid  || this.data.newpassword != this.data.confirmpassword) {

            this.submitted = true;
            return;
        }

        this.closeModal({ status: true, data: { password:formData.currentpassword , newpassword:formData.newpassword  } });
    }
}