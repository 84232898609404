import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'dialog-ok.component',
    templateUrl: './dialog-ok.component.html',
    styleUrls: ['./dialog-ok.component.scss'],
  })
export class DialogOKComponent {
  @Input() title: string;
  @Input() body: string;

  constructor(protected ref: NbDialogRef<DialogOKComponent>) {
  }

  dismiss() {
    this.ref.close();
  }
}