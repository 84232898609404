<nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      {{detail}}
    </nb-card-body>
    <nb-card-footer>
      <button nbButton hero status="danger" (click)="dismiss()">NO</button>
      <button nbButton hero status="success" (click)="dismiss()">YES</button>
    </nb-card-footer>
</nb-card>
