import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs/Observable";
import { EmptyObservable } from "rxjs/observable/EmptyObservable";
import { Router } from "@angular/router";
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { EnvironmentSpecificService } from "../../../environment-specific.service";

@Injectable()
export class Http {


    constructor(private _http: HttpClient,
        private _router: Router,
        private _envService: EnvironmentSpecificService) {

    }

    public get(url, option?): Observable<Response> {

        return this.intercept(this._http.get(url,this.prepareRequest(option)));
    }
    
    public post(url, data?, option?): Observable<Response> {

        return this.intercept(this._http.post(url, data,this.prepareRequest(option)));
    }

    public getAnonymous(url, option?): Observable<Response> {

        return this.intercept(this._http.get(url,this.prepareAnonymousRequest(option)));
    }
    
    public postAnonymous(url, data?, option?): Observable<Response> {

        return this.intercept(this._http.post(url, data,this.prepareAnonymousRequest(option)));
    }


    public checkAuthorized(): boolean {

        let currentUser = JSON.parse(localStorage.getItem("currentUser"));

        if (currentUser) {

            return true;
        }

        return false;
    }

    public validToken(): Observable<any>{
        return this.get(this._envService.envSpecific.apiUrl  + '/get-start/validtoken').map((response: Response) => {
    
            return response;
        });
    }

    private prepareRequest(option)  {

        const currentUser = this.getCurrentUser();

        let token: string = null;

        if (currentUser) {

            token = currentUser.token;
        }

        
        if(option != null && option.headers!=null){

            option.headers = option.headers.append('Authorization',  "Bearer ".concat(token));
        }else{
            
            option = {
                headers:new HttpHeaders().set('Authorization',  "Bearer ".concat(token)).set('timezone',Intl.DateTimeFormat().resolvedOptions().timeZone)
            }
        }

        return option;
    }
    private prepareAnonymousRequest(option)  {

        if(option != null && option.headers!=null){

            option.headers = option.headers.append('x-mekha',  '8c7edf4c-aeb9-4a6c-9bab-19e94cb965b8');
        }else{
            
            option = {
                headers:new HttpHeaders().set('x-mekha',   '8c7edf4c-aeb9-4a6c-9bab-19e94cb965b8')
            }
        }

       

        return option;
    }

    private intercept(observable: Observable<any>) {
        return observable.map((res: any) => {

            if (res) {
                if (this.isJson(res._body) && JSON.parse(res._body).status == "systemError") {

                    this._router.navigate(["/"]);
                    return new EmptyObservable<Response>();
                }
            }

            return res;
        }).catch(err => {

            localStorage.removeItem("currentUser");
            
            switch (err.status) {
                case 401:
                    return this.unauthorised();
                case 403:
                    return this.forbidden();
                case 500:
                    return this.internalServerError();
                default:
                    return throwError(err);
            }
        });
    }

    private unauthorised(): Observable<Response> {

        this._router.navigate(["/"]);

        return throwError('Error');
    }

    private forbidden(): Observable<Response> {

        console.log('SESSION TIME OUT!');

        localStorage.setItem('timeout', "true");

        this._router.navigate(["/"]);

        return throwError('Error');
    }

    private internalServerError(): Observable<Response> {

        this._router.navigate(["/"]);

        return throwError('Error');
    }


    private isJson(response: any): boolean {

        if (Array.isArray(response) || (response instanceof ArrayBuffer)) {

            return false;
        }

        if (/^[\],:{}\s]*$/.test(String(response).replace(/\\["\\\/bfnrtu]/g, '@').
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {

            return true;

        }

        return false;
    }

    public getCurrentUser() {

        return JSON.parse(localStorage.getItem("currentUser"));
    }

    public ClearCurrentUser(){
        localStorage.removeItem("currentUser");
    }
}