import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    NbCheckboxModule,
    NbInputModule,
    NbCardModule,
    NbDialogModule,
    NbWindowModule,
    NbButtonModule,
    NbSelectModule,
  } from '@nebular/theme';

import { Toggle } from './components/toggle.component';

import { CustomActionViewComponent } from  './smart-table-custom-button-view/custom-action-view.component';
import { CheckboxButtonViewComponent } from './smart-table-custom-button-view/checkbox-button-view.component';
import { StatusButtonViewComponent } from './smart-table-custom-button-view/status-button-view.component';
import { ActivityLogsButtonViewComponent } from './smart-table-custom-button-view/activity-logs-button-view.component';
import { PinCodeComponent } from './components/pin-code.component';
import { CKEDITORCOMPONENT } from './components/ckeditor.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { Loading } from './components/loading.component';

import { DialogComponent } from './modals/dialog.component';
import { DialogYesNoComponent } from './modals/dialog-yesno.component';
import { DialogOKComponent } from './modals/dialog-ok.component';
import { DialogChangePasswordComponent } from './modals/dialog-change-password';
import { DialogAddInterestComponent } from './modals/dialog-add-interest';

import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { SolarComponent } from '../shared/solar/solar.component'
import { TrafficChartComponent } from '../shared/traffic/traffic-chart.component';
import { TrafficComponent } from '../shared/traffic/traffic.component';
import { ChartModule } from 'angular2-chartjs';
import { AuthorizedDirective } from './authorized.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NbCheckboxModule,
        NbInputModule,
        CKEditorModule,
        NbCardModule,
        NbDialogModule.forChild(),
        NbWindowModule.forChild(),
        NbButtonModule,
        AngularMyDatePickerModule,
        NbSelectModule,
        ChartModule
    ],    
    declarations: [
        AuthorizedDirective,
        CustomActionViewComponent,
        CheckboxButtonViewComponent,
        StatusButtonViewComponent,
        ActivityLogsButtonViewComponent,
        Toggle,
        PinCodeComponent,
        CKEDITORCOMPONENT,
        DialogComponent,
        Loading,
        DialogYesNoComponent,
        DialogOKComponent,
        DialogChangePasswordComponent,
        DialogAddInterestComponent,
        SolarComponent,
        TrafficChartComponent,
        TrafficComponent
    ],
    exports: [
        AuthorizedDirective,
        CustomActionViewComponent,
        CheckboxButtonViewComponent,
        StatusButtonViewComponent,
        ActivityLogsButtonViewComponent,
        Toggle,
        PinCodeComponent,
        CKEDITORCOMPONENT,
        Loading,
        DialogYesNoComponent,
        DialogOKComponent,
        DialogChangePasswordComponent,
        DialogAddInterestComponent,
        SolarComponent,
        TrafficChartComponent,
        TrafficComponent
    ],
    providers: [
    ],
    entryComponents: [
        DialogComponent
     ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule>{
        return <ModuleWithProviders<SharedModule>>{
            ngModule: SharedModule,
            declarations:[AuthorizedDirective],
            providers: [],
        };
    }
}